import React from "react";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";


const Section1 = () => {
  return (
    <>
      <Container
        className="border-top oneContainer1"
        style={{ borderTopColor: "gray" }}
      >
        <Row>
          <Col  sm={12} md={12} lg={6} className="video">
          <iframe
              width="560"
              height="315"
              paddingRight="40px"
              paddingBottom="20px"
              src="https://www.youtube.com/embed/LwEC-G9efQQ?si=iCfgY5ZfJ-tah9lE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            {/* <img
              src="./mProfile.jpeg"
              alt="MFHeadshot"
              className="headshot2"
              align="left"
            /> */}
          </Col>
          {/* <Col md={1}></Col> */}
          <Col sm={12} md={12} lg={6}>
            <p className="text1">
              Dr. Flores focuses her research on health disparities in
              Latina/o/x and other minoritized populations. She employs advanced
              quantitative methods to link social and structural factors to
              health outcomes like cardiovascular disease and mortality. She is
              the director of the Health Equity Analytics Lab (HEAL) which uses
              advanced quantitative techniques like longitudinal analyses,
              latent modeling, and social network analysis to study social
              infrastructure and health in Latina/o/x and other groups
              underrepresented in biomedical literature.
            </p>

            <Link to="/about">
            <button className="button-61" href="/about">
              Learn More
            </button>
            </Link>
            
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Section1;
