import "./App.css";
import TopNavbar from "./Components/Navbar/TopNavbar";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import AcademicWork from "./Pages/Academic/AcademicWork";
import Lab from "./Pages/Lab/Lab";

function App() {
  return (
    <>
      <Router>
        <TopNavbar />
          <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/research">
            <AcademicWork />
          </Route>
          <Route path="/lab">
            <Lab />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
