import React from "react";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";

const TopSection = () => {
  return (
    <>
      <div>
        <Container>
          <br />
          <Row>
            <h1>About</h1>
          </Row>
        </Container>
        <Container
          className="border-top topContainer"
          style={{ borderTopColor: "gray" }}
        >
          <Row>
            <Col lg={4} m={12} s={12} style={{ margin: "auto",     
    display: "block", paddingBottom: "40px" }}>
              <img
                src="./MProfile4.png"
                alt="MFHeadshot"
                className="headshot2"
                align="center"
              />
            </Col>
            {/* <Col md={1}></Col> */}
            <Col lg={8} md={12} sm={12}>
              <h3>Melissa Flores, Ph.D. <span className="pronouns"> ( she,her,hers )</span></h3>
              <br/>
              <p>
                Dr. Flores is an Assistant Professor in the Department of
                Psychology (clinical) at the University of Arizona. As an interdisciplinary
                scientist, her primary focus is on understanding the complex
                interplay of social and structural factors contributing to
                health disparities within the Latina/o/x and other minoritized
                populations. Her approach is centered on resilience, and she
                employs advanced quantitative methods to model social
                environments. Her ultimate goal is to establish links between
                these environments and critical health outcomes, such as
                cardiovascular disease.
              </p>
              <p>
                Dr. Flores is also a distinguished National Heart, Lung, and
                Blood Institute (NHLBI) R00 MOSAIC Fellow. Within her MOSAIC
                project, she leverages machine learning techniques to optimize
                cardiovascular risk assessment specifically tailored to the
                Latina/o/x communities.
              </p>
              <p>
                Furthermore, Dr. Flores is dedicated to fostering diversity in
                academia. She actively engages in mentoring activities across
                various academic disciplines, contributing to the strengthening
                of the academic pipeline for scholars from underrepresented
                backgrounds. 
              </p>
              <p>
              Dr. Flores is accepting a graduate student for the Fall 2023.
              </p>
              <p className="aboutHeaders">EDUCATION</p>
              <p className="educationHeaders">
                Ph.D., Family Studies and Human Development
              </p>
              <a
                href="mailto:maflor@email.arizona.edu"
                target="_blank"
              >
                <button className="button-61">Contact</button>
              </a>
              
            </Col>
          </Row>
        </Container>
        <br />
        <Container>
          <Row>
            <h1>Professional Appointments</h1>
          </Row>
        </Container>
        <br />
        <Container
          className="border-top topContainer"
          style={{ borderTopColor: "gray" }}
        >
          <Col lg={10}>
            <Row>
              <p className="educationHeaders">Assistant Professor</p>
              <p className="subLine">
                Department of Psychology, The University of Arizona | 2023 -
                Present
              </p>
            </Row>
            <br />
            <Row>
              <p className="educationHeaders">
                NHLBI K99 MOSAIC Postdoctoral Fellow
              </p>
              <p className="subLine">
                Department of Psychology, The University of Arizona | 2022 -
                2023
              </p>
            </Row>
            <br />
            <Row>
              <p className="educationHeaders">Postdoctoral Fellow</p>
              <p className="subLine">
                The Center on Health Disparities, Health Sciences, The
                University of Arizona, Health Sciences | 2018 - 2022
              </p>
            </Row>
          </Col>
        </Container>
        <br />
        <Container>
          <br />
          <Row>
            <h1>Courses Taught</h1>
          </Row>
        </Container>
        <Container
          className="border-top topContainer"
          style={{ borderTopColor: "gray" }}
        >
          <Col md={10}>
            <Row>
              <p className="educationHeaders">Structural Equation Modeling</p>
              <p className="indent">
                Basic and intermediate topics of confirmatory factor analysis
                (CFA) and structural equation modeling (SEM).
              </p>
            </Row>
            <Row>
              <p className="educationHeaders">
                Advanced Research Methods
              </p>
              <p className="indent">
                Conceptual foundations of research including
                problem identification, research question/hypothesis
                formulation, measurement, sampling, research designs, and
                statistical analyses.
              </p>
            </Row>

            <Row>
              <p className="educationHeaders">Introduction to Statistics</p>
              <p className="indent">
                An introduction to the foundation of statistical reasoning and
                probability coupled with applied data analysis including
                frequencies, descriptive statistics, and hypothesis testing.
              </p>
            </Row>
            <Row>
              <p className="educationHeaders">Dynamics of Family Relations</p>
              <p className="indent">
                A study of the modern family and its relationships with emphasis
                on interpersonal relationships.
              </p>
            </Row>
          </Col>
        </Container>
        <br />

        <br />
        <br />
      </div>
    </>
  );
};

export default TopSection;
