import React from "react";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <Container>
          <br />
          <Row>
            <Col md={4}>
              <Row>
                <h3>Contact</h3>
              </Row>
              <Row>
                <p className="footerName">Melissa Flores</p>
              </Row>
              <Row className="footerRow3">
                <p>Assistant Professor</p>
                <p>Department of Psychology</p>
                <p>University of Arizona</p>
                <p>1503 E. University Blvd</p>
                <p>Tucson, AZ 85712</p>
              </Row>
              <br />
              <Row className="footerRow3">
                <p>Fax: (520) 621-9306</p>
                <p>Email: maflor@email.arizona.edu</p>
              </Row>
            </Col>
            <Col md={4}></Col>
            <Col md={4}>
              <Row>
                <p className="connect">CONNECT WITH ME</p>
              </Row>
              <Row>
                <Col md={6}>
                  <a href="https://www.linkedin.com/in/melissa-flores-54832428/" target="_blank" className="iconLink">
                    <FontAwesomeIcon icon={faLinkedin} size="xl" />
                  </a>
                  <a href="https://twitter.com/flores_socsci" target="_blank" className="iconLink">
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="icon"
                      size="xl"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div class="mt-auto">
            Footer
        </div> */}
    </>
  );
};

export default Footer;
