import React from "react";
import TopSection from "../../Components/About/TopSection/TopSection";

const About = () => {

    return (
        <>
            <TopSection/>
        </>
    );
};

export default About;