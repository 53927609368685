import React from "react";
import "./style.css";
import { Container, Row, Col} from "react-bootstrap";

const Hero = () => {
  return (
    <div>
      <Container>
        <Row className="hero">
          <Col sm={12} md={1}></Col>
          <Col sm={12} md={5} style={{ backgroundColor: "none" }}>
            <br />
            <Row sm={12} style={{ backgroundColor: "none" }}>
              <p className="title">Social integration is a basic human need.</p>
              <h2 className="heroText2">Yet, social position and culture innervate our social infrastructures at every level of the social ecology.</h2>
              {/* <h2 className="heroText2">Can we optimize health equity initiatives by better understanding social infrastructure?</h2> */}
            </Row>
            <br/>
            {/* <button className="button-61">Click Here</button> */}
          </Col>
          {/* <Col md={1}></Col> */}
          <Col md={5}>
            <img src="./Hands.png" alt="togetherImg" className="headshot" />
          </Col>
          <Col md={1}></Col>
        </Row>
      </Container>
      <br/>
    </div>

  );
};

export default Hero;