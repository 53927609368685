import React from "react";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";

const Section2 = () => {
  return (
    <>
      <Container className="border-top oneContainer">
      <br/>
        <Row>
        <Col md={1}></Col>
          <Col className="quote" sm={12} md={10}>
            <h1>Can we enhance the effectiveness of health equity initiatives through a deeper understanding of social infrastructure?</h1>
          </Col>
          <Col md={1}></Col>
          <Col md={1}></Col>
        </Row>
        <br/>
      </Container>
    </>
  );
};

export default Section2;