import React from "react";
import Research from "../../Components/Research/Research";

const AcademicWork = () => {

    return (
        <>
            <Research/>
        </>
    );
};

export default AcademicWork;