import React from "react";
import LabPage from "../../Components/Lab/LabPage";

const Lab = () => {

    return (
        <>
            <LabPage/>
        </>
    );
};

export default Lab;