import React from "react";
import "./style.css";
import { Nav, Navbar } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
// import UALogo from "./ualogo.png"

const TopNavbar = () => {
  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <Container>
      <Navbar.Brand href="/">
      <img src="./ualogo.png" alt="UofALogo" className="ua-logo" />
        </Navbar.Brand>
        <Navbar.Brand href="/" className="nav-title">
          Melissa Flores PhD
          <p className="nav-sub">
            Assistant Professor
          </p>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto nav">
            <Nav.Link as={Link} to="/research" className="nav-links">Research</Nav.Link>
            <Nav.Link as={Link} to="/about" className="nav-links">About</Nav.Link>
            <Nav.Link as={Link} to="/lab" className="nav-links">Lab</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopNavbar;
