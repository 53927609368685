import React from "react";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";

const Research = () => {
  return (
    <>
      <div>
        <Container>
          <br />
          <Row>
            <h1>Research</h1>
          </Row>
        </Container>
        <Container
          className="border-top topContainer"
          style={{ borderTopColor: "gray" }}
        >
          <Row>
            {/* <Col md={1}></Col> */}
            <Col lg={8} md={12} sm={12}>
              <p className="healText">
                Dr. Flores' research is centered on the exploration of social
                resilience in Latina/o/x Hispanic populations concerning disease
                and mortality. Her work aims to understand the structural
                factors that underlie the persistence of health disparities
                while employing advanced quantitative methods to capture complex
                social systems.
              </p>

              <p className="aboutHeaders">SELECTED PUBLICATIONS</p>
              <a
                href="https://scholar.google.com/citations?user=-NOSjAoAAAAJ&hl=en&oi=ao"
                className="linkURL"
              >
                <p className="educationHeaders">h-index: 10; i-10 index: 10</p>
              </a>
              <br />
              <Row>
                <p className="pubTitle">
                  Anti-racism efforts in healthcare: A selective review from a
                  social cognitive perspective.
                </p>
                <p>
                  Brondolo E., Kaur, A., Seavy R., <strong>Flores, M.</strong>,{" "}
                  <span>&#38;</span> Collaborative Health Integration Research
                  Program (CHIRP) DEI Writing Group, (in press) Policy Insights
                  from the Behavioral and Brain Sciences.
                </p>
              </Row>
              <Row>
                <p className="pubTitle">
                  Systemic racism drives health disparities: Implications for
                  policy. (in press).{" "}
                </p>
                <p>
                  Brondolo, E., Kaur, A., <strong>Flores, M.</strong>,{" "}
                  <span>&#38;</span> Social Issues and Policy Review.
                </p>
              </Row>
              <Row>
                <a
                  href="https://pubmed.ncbi.nlm.nih.gov/36729601/"
                  target="_blank"
                  className="linkURL"
                >
                  <p className="pubTitle">
                    Examining between group differences in social network
                    density and hs-CRP in older adults: Implications for the
                    Hispanic Mortality Paradox.
                  </p>
                </a>
                <p>
                  <strong>Flores, M.</strong>, O'Neill, R. M., Boyd, S. M.,
                  Uchino, B.N., Ruiz, J. M., (2023). Psychosomatic Medicine.
                </p>
              </Row>
              <Row>
                <a
                  href="https://link.springer.com/article/10.1007/s10865-021-00273-3"
                  className="linkURL"
                >
                  <p className="pubTitle">
                    Ethnic differences in survival among lung cancer patients: a
                    systematic review.
                  </p>
                </a>
                <p>
                  Price, S. N.,<strong>Flores, M.</strong>, Hamann, H. A., Ruiz,
                  J.M., (2021). Journal of the National Cancer Institute, Cancer
                  Spectrum. Doi: https://doi.org/10.1093/jncics/pkab062.
                </p>
              </Row>
              <Row>
                <a
                  href="https://academic.oup.com/jncics/article/5/5/pkab062/6317681"
                  className="linkURL"
                >
                  <p className="pubTitle">
                    Ethnic differences in survival among lung cancer patients: a
                    systematic review.
                  </p>
                </a>
                <p>
                  Price, S. N., <strong>Flores, M.</strong>, Hamann, H. A.,
                  Ruiz, J.M., (in press) Journal of the National Cancer
                  Institute, Cancer Spectrum. doi:10.1093/jncics/pkab062.
                </p>
              </Row>
              <Row>
                <a
                  href="https://academic.oup.com/abm/article/56/1/21/6209756?login=true"
                  className="linkURL"
                >
                  <p className="pubTitle">
                    Hispanic ethnic density may be protective for older
                    Black/African American and non-Hispanic White populations
                    for some health conditions: An exploration of support and
                    neighborhood mechanisms.
                  </p>
                </a>

                <p>
                  <strong>Flores, M.</strong>, Ruiz, J. M., Butler, E. A.,{" "}
                  <span>&#38;</span> Sbarra, D. A., (2021) Annals of Behavioral
                  Medicine doi: 10.1093/abm/kaab014.
                </p>
              </Row>
              <Row>
                <a
                  href="https://academic.oup.com/abm/article/55/7/612/6101160"
                  className="linkURL"
                >
                  <p className="pubTitle">
                    Does the Hispanic mortality advantage vary by marital status
                    among post-menopausal women in the Women's Health
                    Initiative?
                  </p>
                </a>
                <p>
                  <strong>Flores, M.</strong> Ruiz, J. M., Butler, E. A.,
                  Sbarra, D. A., Garcia, D. O., ..., <span>&#38;</span> Thomson
                  C. A., (2021). Annals of Behavioral Medicine
                  doi:10.1093/abm/kaaa113
                </p>
              </Row>
              <Row>
                <a
                  href="https://pubmed.ncbi.nlm.nih.gov/33219603/"
                  className="linkURL"
                >
                  <p className="pubTitle">
                    Within-person associations between racial microaggressions
                    and sleep among African American and Latinx young adults.
                  </p>
                </a>

                <p>
                  Davenport, M. A., Landor, A. M., Zeiders, K. H., Sarsar, E.
                  D., <span>&#38;</span> <strong>Flores, M.</strong> Journal of
                  Sleep Research doi:10.1111/jsr.13226.
                </p>
              </Row>

              <Row>
                <a
                  href="https://psycnet.apa.org/record/2019-22446-001"
                  className="linkURL"
                >
                  <p className="pubTitle">
                    Racial-ethnic differences in social networks and support:
                    Measurement considerations, and implications for disparities
                    research.{" "}
                  </p>
                </a>

                <p>
                  <strong>Flores, M.</strong> , Ruiz, J. M., Goans, C., Butler,
                  E. A., Uchino, B.N., Hirai, M., ..., <span>&#38;</span> Smith,
                  T. W. (2020) Cultural Diversity and Ethnic Minority Psychology
                  26(2):189-199. doi:10.1037/cdp0000283.
                </p>
              </Row>
              <Row>
                <a
                  href="https://www.sciencedirect.com/science/article/abs/pii/S1054139X18302064"
                  className="linkURL"
                >
                  <p className="pubTitle">
                    Microaggressions and diurnal cortisol: Examining
                    within-person associations among African American and Latino
                    young adults.{" "}
                  </p>{" "}
                </a>

                <p>
                  Zeiders, K. H., Landor, A. M., <strong>Flores, M.</strong>,{" "}
                  <span>&#38;</span> Brown, A. (2018). The Journal of Adolescent
                  Health. 63, 482-488. doi: 10.1016/j.jadohealth.2018.04.018
                </p>
              </Row>
              <Row>
                <a
                  href="https://journals.sagepub.com/doi/abs/10.1177/0192513X18783807?journalCode=jfia"
                  className="linkURL"
                >
                  <p className="pubTitle">
                    Family socialization for racism and heterosexism:
                    Experiences of Latinx sexual minority adolescents and young
                    adults.{" "}
                  </p>{" "}
                </a>

                <p>
                  Toomey, R. B., Shramko, M., <strong>Flores, M.</strong>,{" "}
                  <span>&#38;</span> Anhalt, K. (2018) The Journal of Family
                  Issues. 39, 3586-3611. doi: 10.1177/0192513X18783807.
                </p>
              </Row>

              <br />
              <br />

              {/* <p>Featured publication: https://www.whi.org/news/mortality-advantage-identified-among-postmenopausal-u-s-hispanic-women.</p> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Research;
