import React from "react";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";

const LabPage = () => {
  return (
    <div>
      <Container>
        <br />
        <br />
        <Row>
          <h1>My Lab - HEAL</h1>
        </Row>
      </Container>
      <Container
        className="border-top topContainer"
        style={{ borderTopColor: "gray" }}
      >
        <Row lg={12}>
          <p className="healText">
            Welcome to the Health Equity Analytics Lab (HEAL)! Our lab is a
            space for scientists, trainees, and community members to explore
            publicly available data and participate in research aimed at
            understanding persistent health disparities in Latina/o/x
            populations and other minoritized groups.
          </p>
          <p className="healText">
            In our research, we employ advanced quantitative methodologies to
            capture the intricate aspects of social infrastructure. These
            methods include advanced longitudinal analyses, latent modeling,
            person-centered analyses, as well as non-linear techniques such as
            social network analysis and geographic information systems analysis.
            By utilizing both publicly available data, we aim to uncover unique
            layers of an individual's social environment.
          </p>
          <p className="healText">
            We encourage underrepresented trainees to join us. Scientists and
            trainees from underrepresented backgrounds often face significant
            challenges, including interpersonal and structural racism, gender
            bias, and sexual prejudice. Our mentoring experiences are designed
            to promote diversity and inclusion, helping to mitigate these
            stressors and support the success of early-career, diverse trainees.
          </p>
          <p className="healText">
            Above all, our lab fosters a cooperative and warm collaborative
            research environment that emphasizes strengths and the shared
            success of everyone.
          </p>
        </Row>
      </Container>
      <Container>
        <br />
        <br />
        <Row>
          <h1>Students</h1>
        </Row>
      </Container>
      <Container
        className="border-top topContainer"
        style={{ borderTopColor: "gray" }}
      >
        <Row>
          <Col lg={4} m={12} sm={12} className="imgCol">
            <img
              src="./Gio.png"
              alt="GioHeadshot"
              className="headshot2"
              align="left"
            />
          </Col>
          {/* <Col md={1}></Col> */}
          <Col lg={8} m={12} sm={12}>
            <h3>Giovanni Marquez</h3>
            <p>he,him,his</p>
            <p>
              My name is Giovanni Marquez, I am a graduate student in Clinical
              Psychology at The University of Utah. I am an alumni member of the
              Social Risk and Resilience factors (SuRRF) lab and the Laboratory
              for Social Connectedness and Health in the UA’s Department of
              Psychology. After graduating, I aim to pursue a career in academia
              as a researcher and tenure-track professor. In my free time I
              enjoy watching combat sports, training Brazilian Jiu-Jitsu,
              raising tropical house plants, watching anime, and cooking!{" "}
            </p>
            <p className="aboutHeaders">EDUCATION</p>
            <p className="educationHeaders">
              Bachelor of Science in Psychological Science
            </p>
            <p>University of Arizona, Tucson, AZ</p>
            <a
              href="https://drive.google.com/file/d/1apwIK_Pm6PdCHBRhipMzZPj_TKIA2mFH/view?usp=sharing"
              target="_blank"
            >
              <button className="button-61">View CV</button>
            </a>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <Row>
          <Col lg={4} m={12} sm={12} className="imgCol">
            <img
              src="./AIM_pic.png"
              alt="GioHeadshot"
              className="headshot2"
              align="left"
            />
          </Col>
          {/* <Col md={1}></Col> */}
          <Col lg={8} m={12} sm={12}>
            <h3>Amelia Ibarra Mevans</h3>
            <p>she,her,hers</p>
            <p>
              Amelia is a current Clinical Psychology PhD student. She graduated
              with honors from the University of Sonora, Mexico. Amelia is
              interested in the research of psychosocial factors leading to
              cardiovascular/cardiometabolic disease risk, as well as
              racial/ethnic health disparities. She takes pride in being a
              Mexican woman and is very enthusiastic of diversity and
              representation in science. In her free time, Amelia enjoys
              drinking coffee with friends, hiking, skating, and cooking for her
              friends and family.{" "}
            </p>
            <p className="aboutHeaders">EDUCATION</p>
            <p className="educationHeaders">Bachelor of Arts: Psychology</p>
            <p>University of Sonora—Hermosillo, SON</p>
            <a
              href="https://drive.google.com/file/d/1i3sV9tzo-q9BVvcwqWE44vAhJOcIcsqn/view?usp=sharing"
              target="_blank"
            >
              <button className="button-61">View CV</button>
            </a>
          </Col>
        </Row>
      </Container>
      <br />
    </div>
  );
};

export default LabPage;
