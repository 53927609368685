import React from "react";
import Hero from "../../Components/Hero/Hero"
import Section1 from '../../Components/Section1/Section1';
import Section2 from '../../Components/Section2/Section2';

const Home = () => {
  return (
    <>
      <Hero />
      <Section2 />
      <Section1 />
    </>
  );
};

export default Home;
